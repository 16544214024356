@import "utilities/variable.scss";
*{
  box-sizing: border-box; 
}

.disable-select {
  -webkit-user-select: none;  
  -moz-user-select: none;    
  -ms-user-select: none;      
  user-select: none;
}

.App {
  text-align: center;
  font-family:Verdana ;
  font-weight: 600;
  min-height: 100vh;
  /* height: 100vh; */
  // background-image: url('./images/softball-field-background.jpg');
  background-repeat: no-repeat, repeat;
  background-size: cover;
  background-position: top;
  background-attachment: fixed;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: var(--main-darkyellow);
}

.container{
  padding: 100px 0 55px;
  padding-top: 0;
  height: 91%;
  /* background-color: var(--red); */
  /* background-image: url('./images/behind-the-plate.jpg');
  background-repeat: no-repeat, repeat;
  background-size: cover;
  background-position: center; */
  background-color: var(--main-darkyellow);

}

.container-after{
  background: var(--black-transparent);
  height: 100%; 
  padding-top: .1px;  
  color: var(--text-white);
}
.button-main{
  background-color: var(--main-yellow);
  border: 4px solid var(--red);
  /* border-style: groove; */
  padding: 8px 75px 10px;
  font-size: 1.4rem;
  color: white;
  font-weight: bold;
  border-radius: 12px;
  transition: all 0.3s;
}
.button-main:focus{
  outline: none;
}
.button-main:hover{
  background: var(--main-lightyellow);
  color: var(--red);
  border: 4px solid var(--red);
  box-shadow: 0px 0px 15px 8px rgba(0,0,0, .575);
  cursor: pointer;
}
.button-main:active{
  background:   var(--main-darkyellow);
  color:  var(--text-white);
}





