@import '../../utilities/variable.scss';

.Contact{
    padding: 100px 0;
}

.contact-container{
    background: rgba(0, 0, 0, 0.88);
    border: 3px solid var(--red);
    outline: 3px solid var(--white);
    border-radius: 2px;
    width: 78%;
    // max-width: 600px;
    margin:  35px auto;
    box-shadow: 0px -50px 450px 40px var(--red);
    margin-top: -90px;;
}
.contact-container h2{
    font-size: 3rem !important;
    // text-shadow: -5px 0px 0px var(--white);
    // padding: 10px 30px;
    // color: var(--red);
    // border-bottom: 2px solid var(--red);
    // padding: 20px 0 20px;
    // margin: 0 0 40px;
    
  }

.contact-section{
    margin: 35px;
    display: flex;
    justify-content: center;
}

.contact-section .contact-info{
    display: flex;
    margin: 20px 0;
    justify-content: center;
    flex-direction: column;
    width: 20%;
    min-width:300px;
}

.contact-section input{
    
    // width: 33%;
    flex-direction: flex-start;
    // background-color: var(--black-transparent);
    border: none;
    // outline-style: solid;
    // outline-color: white;
    // outline-width: 2px;
    padding: 7px 0 7px 8px;
    color: var(--text-white);
    font-size: 1.15rem;
    // width: 200px;
    max-height: 46px;
    text-align: center;
    background-color: inherit;
}
.contact-section input:focus{
    border:none;
    outline: none;
}
.contact-section .label{
    // margin: 0 30px 0 0;
    // width: 185px;
    // background: var(--black-transparent);
    background-color: inherit;
    padding: 10px 8px 5px;
    border-bottom : 2px solid var(--red);
    // box-shadow: -1px -5px 15px var(--black-transparent);
    // border-top-right-radius: 20px;
    // border-top-left-radius: 20px;
    font-size: 1.2rem;
}


// copy button
// ==================================

.mail-text{
    // position: absolute;
    left: 85px;
    top: -4px;
    // background: white;
    
    font-size: 1.3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    button{
        background-color: var(--black-transparent);
        // border: 2px solid var(--red);
        font-size: .8rem;
        width: 60px;
        padding:  0 2px;
        height: 45px;
        margin-left: 10px;
        color: var(--text-white);
    }
    #mailInput{
        font-size: 1.1rem;
        background: var(--white);
        padding: 5px 10px;
        border-radius: 5px;
    }

    //Tooltip

    .tooltip {
        position: relative;
        display: inline-block;
      }
      
      .tooltip .tooltiptext {
        visibility: hidden;
        width: 140px;
        background-color: #555;
        color: #fff;
        text-align: center;
        border-radius: 6px;
        padding: 5px;
        position: absolute;
        z-index: 1;
        bottom: 150%;
        left: 50%;
        margin-left: -75px;
        opacity: 0;
        transition: opacity 0.3s;
      }
      
      .tooltip .tooltiptext::after {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: #555 transparent transparent transparent;
      }
      
      .tooltip:hover .tooltiptext {
        visibility: visible;
        opacity: 1;
      }

}

// phone styles 
// ========================

@media (max-width: $phone){
    .contact-section {
        input{
            font-size: 1rem;
            padding-top: 10px;
            padding-bottom: 10px;
        }

        .contact-info{
            flex-direction: column;
        }
        .label{
            margin: 0;
            width: 100%;
        }
    
    }
}