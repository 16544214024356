/* Reset some default styles */
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
li {
  margin: 0;
  padding: 0;
}

/* Global styles */
body {
  font-family:Verdana ;
  line-height: 1.6;
}

header {
  background-color: #333;
  padding: 10px 0;
}

nav ul {
  list-style-type: none;
  text-align: center;
}

nav ul li {
  display: inline-block;
  margin-right: 10px;
}

nav ul li a {
  color: #fff;
  text-decoration: none;
  padding: 5px;
}
#home-container{
  padding-top:0px;
  background-image: url('../../images/trainer.jpg');
  min-height: 100%;
background-repeat: no-repeat;
background-attachment: fixed;
background-position: center;
background-size: cover;
}
#home{
  padding-top:0px !important;
}
#home h2{
  font-style: italic;
  font-family: 'Satisfy';
  font-size:33px;
}
.hero {
  text-align: center;
  padding: 155px 0 100px;
  background-color: rgba(255,255,255,0.45);
  background: radial-gradient(#ffffff, transparent);
  background: radial-gradient(ellipse at top, rgba(205,205,205,0.6) 50%, transparent ),radial-gradient(ellipse at bottom, transparent, transparent);
  color:black !important;
  
}

.hero h1 {
  font-size: 46px;
  margin-bottom: 20px;
  font-style: italic;
  font-family: 'Satisfy';
  font-weight: 600;
}

.hero p {
  font-size: 20px;
  margin-bottom: 30px;
}

.btn {
  display: inline-block;
  background-color: #333;
  color: #fff;
  text-decoration: none;
  padding: 10px 20px;
  border-radius: 5px;
}
.btn:hover{
  cursor: pointer;
}
.about-container {
  text-align: center;
  padding: 100px 15px 10px;
  display: flex;
  justify-content: space-around;
}
.about-container > * {
  width:50%;
}

.about-container h2 {
  
  margin-bottom: 20px;
}
.about-container img {
  max-height:400px;
  max-width:400px;
  aspect-ratio: 1 1;
  margin-top:-60px;
  margin-bottom:30px;
  max-width: 100%;
    object-fit: contain;
}
#contanctForm{
  width:65%;
  margin:0 auto;
}
#contanctForm input, #contanctForm textarea{
  border-radius: 3px;
}
#services{
  background-image: url('../../images/hatching-butterfly.jpg');
  min-height: 100%;
background-repeat: no-repeat;
background-attachment: fixed;
background-position: center;
background-size: cover;
}
.services-container {
  text-align: center;
  padding: 100px 15px;
  background-color: rgba(0,0,0,0.3);
  background: radial-gradient(ellipse at top, var(--main-yellow) , transparent),radial-gradient(ellipse at bottom, var(--main-yellow), transparent);
}

.services-container h2 {
  
  margin-bottom: 20px;
}

.services-container ul {
  list-style-type: none;
  margin-bottom: 30px;
}

.services-container ul li {
  font-size: 20px;
  margin-bottom: 10px;
}

.testimonials-container {
  text-align: center;
  padding: 100px 15px;
  display: flex;
  justify-content: space-around;
}
.testimonials-container > * {
  width:50%;
}
.testimonial-image{
  max-height:500px;
  max-width:500px;
  width: 50%;
  // height: 100%;
  margin-top:-120px;
  margin-bottom:-120px;
  aspect-ratio: 1 1;
  border-radius: 100%;
  z-index: 10;
}
.testimonials-container img {
  max-height:500px;
  max-width:500px;
  aspect-ratio: 1 1;
  width:100%;
  // object-fit: contain;
  border-radius: 3000px;
}

.testimonials-container h2 {
  
  margin-bottom: 20px;
}

.testimonial {
  margin-bottom: 30px;
}

.testimonial img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-bottom: 10px;
}

.client-name {
  font-style: italic;
}

.contact-container {
  text-align: center;
  padding: 100px 15px;
}

.contact-container h2 {
  
  margin-bottom: 20px;
}

.contact-container p {
  margin-bottom: 30px;
}

form input,
form textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
}

form button {
  background-color: #333;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

footer {
  background-color: #333;
  color: #fff;
  padding: 10px;
  text-align: center;
}

footer p {
  font-size: 14px;
  margin-bottom: 10px;
}

.social-icons img {
  width: 20px;
  height: 20px;
  margin: 0 5px;
}
#wholeistic{
  background-image: url('../../images/relaxing-background.png');
  min-height: 100%;
background-repeat: no-repeat;
background-attachment: fixed;
background-position: center;
background-size: cover;
}
#wholeistic .wholeistic-container{
  width:100%;
  justify-content: space-around;
  display:flex;
  align-items: center;
  background: radial-gradient(ellipse at top, var(--red) 1%, transparent),radial-gradient(ellipse at bottom, var(--red) 1%, transparent);
}
.zen-image-container{
  max-height: 500px;
    max-width: 500px;
    width: 50%;
    margin-top: 30px;
    margin-bottom: -100px;
    margin-left:-120px;
    aspect-ratio: 1 1;
    border-radius: 100%;
    z-index: 10;
}
.zen-image-container img{
  max-height: 500px;
  max-width: 500px;
  aspect-ratio: 1 1;
  width: 100%;
  border-radius: 3000px;
}
.zen-empty{
  // height:500px;
  min-height:300px;
}

#contact{
  background-image:url('../../images/wholeistic.jpg');
  background-repeat: no-repeat;
// background-attachment: fixed;
background-position: center;
background-size: cover;
background: radial-gradient(ellipse at top, var(--red) 2%, transparent ),radial-gradient(ellipse at bottom, var(--red) 2%, transparent);
}
.send-btn{
  font-size:1.25rem;
  background-color: var(--main-yellow);
}
.send-btn:hover{
  font-size:1.25rem;
  background-color: var(--main-darkyellow);
}

@media (max-width: 860px){
  .testimonials-container, .about-container{
    flex-direction: column;
  }
  .testimonials-container > *, .about-container > *,.zen-image-container{
    width:100%;
    margin-bottom:0;
  }
  .about-container{
    padding-bottom:60px;
  }
  
  .about-container img, .testimonial-image, .zen-image-container{
    margin: 10px auto;
    // margin-bottom:10px;
    // margin-top:10px;
    
  }
  
  .about-container img{
    margin-top:-50px;
    margin-bottom:30px;
  }
}
@media (max-width: 1100px){
  .zen-image-container{
    width: 80%;
    margin-bottom:-5px;
    margin-top:70px;
  }
}
@media (max-width: 850px){
  .contact-section{
    flex-direction: column;
    align-items: center;
  }
}